import React , { useState, useEffect } from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'

import axios from 'axios'
import EventsMap from '../Groups/EventsMap'

const Events = () => {
  //const [events, setEvents] = useState()

  /*
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:3001/events');
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.log('Error fetching events:', error);
      }
    };

    fetchData();
  }, []);

  */

  const events = [
    {name: "The Scottish Crannog Centre: A Medieval Takeover! ",
      start_date: new Date("2024-06-08"),
      end_date: new Date("2024-06-16"),
      location: "Scottish Crannog Center",
      description: "Bringing back the popular Boar Hunt to help feed our hungry re-enactors, come and see what life was like in 14th Century Scotland. A chance to see medieval combat, learn the laws of the time as presented by the Court of our local Lord, see the physical training endured to become a Knight and talk to our volunteers about the crafts and skills of the time that they are bringing to life. ",
      coordinates: {y: "56.5891", x: "-4.02015"}
    },

    {name: "Bannockburn Heritage Centre",
    start_date: new Date("2024-06-23"),
    end_date: new Date("2024-06-23"),
    location: "Bannockburn Heritate Center",
    description: "From medieval combat, kids armies, Arming a Knight and a variety of craft displays in our medieval tents, come along and speak with the volunteers bringing 14th Century Scotland to life! Learn about the crafts and skills of the time, watch the training that goes into becoming a Knight, get a close-up view of the armour and weapons and discover the laws of the time at the Court of the local Lord.",
    coordinates: {y: "56.09261", x: "-3.93507"}
  }

  ]

  return (
    <>
    <header className=" flex flex-wrap ">
    <div className="hero2 flex flex-col items-center w-full py-40">
            <h1 className="text-2xl font-bold leading-snug tracking-tight text-gray-300 lg:text-3xl lg:leading-tight xl:text-3xl xl:leading-tight ">
          Events 2024
        </h1>
    </div>
  </header>
  <section>

    <div class=" text-left  md:w-60vw w-80vw " >
      {events?.sort((a,b) => a.start_date - b.start_date).filter((event) => event.end_date > new Date()).map((event) => 
        <div key={event.name} className='bg-white  border  md:w-auto p-8 mb-8 '>        
            <div className='flex gap-4 flex-col'>
             <b className=''>{event.start_date.toLocaleDateString() } - {event.end_date.toLocaleDateString() }</b>
              <h3 className='text-xl'>{event.name} </h3>
              <p className='opacity-80 italic font-light'>{event.description}</p>
              <a href={"https://www.google.com/maps/dir//" + event.location} target="_blank"  className='flex items-center gap-1'><GeoAltFill className='text-sky-500 text-xl'/>{event.location}</a>
            </div>

        
        </div>

      )}

  </div>
  <br />

  </section>

  <div id="map" class=" bg-sky-500 w-full " >

    <EventsMap events={events}/>




</div>
  </>    
  )
}

export default Events