import { Routes, Route } from 'react-router-dom'
import { useState } from 'react';

import Home from './pages/Home/Home'
import About from './pages/About/About';
import Groups from './pages/Groups/Groups';
import Contact from './pages/Contact/Contact';
import Events from './pages/Events/Events';
import Login from './pages/User/Login';
import Register from './pages/User/Register';
import Dashboard from './pages/User/Dashboard';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Membership2023 from './components/Forms/Membership2023';

function App() {

  return (
    <>
    <Navbar />
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/about" element={<About />} />
       <Route path="/groups" element={<Groups />} />
       <Route path="/events" element={<Events />} />
       <Route path="/contact" element={<Contact />} />

    </Routes>
    
    <Footer/>
 </>
  );
}



export default App;
