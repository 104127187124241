import React from 'react'
import { Link } from 'react-router-dom'
import { Facebook, Instagram, Envelope, Discord } from 'react-bootstrap-icons'

import borders from '../../img/borderslogo.png'
import strathclyde from '../../img/strathclydelogo.png'
import falkirk from '../../img/falkirklogo.jpg'
import dundee from '../../img/dundeelogo.jpg'
import edinburgh from '../../img/edinburghlogo.jpg'


const containerStyle = {
    width: '100vw',
    height: '40vh'
  };
  
  const center = {
    lat: 56,
    lng: -3.886995
  };
  const strath ={lat: 55.86157266670616, lng: -4.242296059753878}



const Groups = () => {


  return (
    <>
    {/*
      <LoadScript
      googleMapsApiKey="AIzaSyCka3XVifIeG0eCOOfmWqiDu4bwb0uyZ7k"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
      >
   

        <></>
      </GoogleMap>
    </LoadScript>
    */}
       <header className=" flex flex-wrap ">
        <div className="hero2 flex flex-col items-center w-full py-40">
            <h1 className="text-2xl font-bold leading-snug tracking-tight text-gray-300 lg:text-3xl lg:leading-tight xl:text-3xl xl:leading-tight ">
              Our Groups
            </h1>
        </div>
      </header>


      <section className='md:w-3/4 m-auto '>

        <div className="text-center md:text-left flex flex-row gap-10 my-10 text-left flex-wrap lg:flex-nowrap">
            <div className="w-100 md:w-1/5  flex flex-col justify-around" >
                <img className="h-1/3 m-auto p-3" src={edinburgh} alt="Edinburgh Group Logo" />
                <h2 className='text-2xl text-center pb-6'>Edinburgh</h2>
                <p><b>Meeting Times:</b></p>
                <p>Saturday 12.00 - 17.00  (except shows)</p>
                <p><b>Location:</b> Craigmillar Castle, Craigmillar Castle Road, Edinburgh, EH16 4SY</p>    
                <p><b>Get in touch:</b></p>
                <div className='flex gap-4 pt-4 justify-center md:justify-start'>
                    <Link to="https://www.facebook.com/EdinburghBXIV "><Facebook className='text-2xl text-gray-800'/></Link>
                    <a href="mailto:BXIV.Edinburgh@gmail.com"><Envelope className='text-2xl text-gray-800'/></a>
                </div>
            </div>
            <div className="w-100 md:w-1/5 flex flex-col justify-around" >
                <img className="h-1/3 m-auto p-3" src={strathclyde} alt="Strathclyde Medieval Society Heraldry" />
                <h2 className='text-2xl text-center pb-6'>Strathclyde</h2>
                <p><b>Meeting Times:</b></p>
                <p>Saturday 12:00 - 18:00 (except shows)</p>
                <p><b>Location:</b> Strathclyde Student Union, 51 Richmond St, Glasgow G1 1XU</p> 
                <p><b>Get in touch:</b></p>
                <div className='flex gap-4  pt-4  justify-center md:justify-start'>
                    <Link to="https://discord.gg/VfMDexs4jD"><Discord className='text-2xl text-gray-800'/></Link>
                    <Link to="https://www.instagram.com/strathmedieval/"><Instagram className='text-2xl text-gray-800'/></Link>
                    <a href="mailto:sumedievalsociety@gmail.com"><Envelope className='text-2xl text-gray-800'/></a>
                </div>
            </div>
            <div className="w-100 md:w-1/5  flex flex-col justify-around" >
                <img className="h-1/3 m-auto p-3" src={falkirk} alt="Falkirk Group logo" />
                <h2 className='text-2xl text-center pb-6'>Falkirk</h2>
                <p><b>Meeting Times:</b></p>
                <p>Saturday 12:00 - 16:00 (except shows)</p>
                <p><b>Location:</b> Kinneil Estate, Bo'ness, EH51 0PJ </p> 
                <br />
                <p><b>Get in touch:</b></p>
                <div className='flex gap-4  pt-4 justify-center md:justify-start'>
                    <Link to="https://www.facebook.com/groups/813827825800109/?ref=share"><Facebook className='text-2xl text-gray-800'/></Link>
      
                </div>
            </div>
            <div className="w-100 md:w-1/5 flex flex-col justify-around" >
                <img className="h-1/3 m-auto p-3"src={borders} alt="Borders Group Heraldry" />
                <h2 className='text-2xl text-center pb-6'>Borders</h2>
                <p><b>Meeting Times:</b></p>
                <p>Saturday 12:00 - 17:00 (except shows)</p>
                <p><b>Location:</b> Harmony Orchard (Opposite Harmony Garden), St Mary's Rd, Melrose ,TD6 9LJ</p> 
                <p><b>Get in touch:</b></p>
                <div className='flex gap-4  pt-4 justify-center md:justify-start'>
                    <Link to="https://www.facebook.com/groups/392640405472337"><Facebook className='text-2xl text-gray-800'/></Link>
             
                </div>
            </div>
            <div className="w-100 md:w-1/5 flex flex-col justify-around" >
                <img className="h-1/3 m-auto p-3" src={dundee} alt="Dundee Medieval Society Heraldry" />
                <h2 className='text-2xl text-center pb-6'>Dundee</h2>
                <p><b>Meeting Times:</b></p>
                <p>Saturday 12:00-18:00</p>
                <p>Wednesday 13:00-18:00</p>
                <p>(During academic year)</p>
                <p><b>Location:</b>Univeristy of Dundee, Nethergate, Dundee, DD1 4HN</p> 
                <p><b>Get in touch:</b></p>
                <div className='flex gap-4  pt-4 justify-center md:justify-start'>
                    <Link to="https://m.facebook.com/uodmedieval"><Facebook className='text-2xl text-gray-800'/></Link>
                    <Link to="https://www.instagram.com/uod_medieval"><Instagram className='text-2xl text-gray-800'/></Link>
                    <a href="mailto:dmrs@dundee.ac.uk"><Envelope className='text-2xl text-gray-800'/></a>
                </div>
            </div>

        </div>
        

      </section>
      <div style={{width: "100%", overflow: "hidden", height: "500px"}}>
        <iframe src="https://www.google.com/maps/d/embed?mid=1gDIFdhxr9BYXy5ZFjn5_xMrgs43_4Rs&ehbc=2E312F" width="100%" height="700" style={{marginTop : "0"}}></iframe>

      </div>
    </>
  )
}

export default Groups