import React from 'react'
import { Link } from 'react-router-dom'
import { Facebook, Instagram } from 'react-bootstrap-icons'

const Footer = () => {
  return (
    <footer className='bg-sky-400 '>
        <div className='w-2/3 m-auto flex md:flex-row justify-center justify-around pb-10 pt-16 text-white flex-col'>
            <div className="w-1/3 flex flex-col flex-wrap lg:flex-nowrap mb-10 ">
                <h3 className="text-xl  ">
                Britannia XIV
                </h3>
                <p className="text-gray-800 pt-4">
                    Britannia XIV is a medieval re-enactment recreating life in 14th Century Scotland.
                </p>
                <div className='flex gap-4  pt-4'>
                    <Link to="https://www.facebook.com/BritanniaXIV/"><Facebook className='text-2xl text-gray-800'/></Link>
                    <Link to="https://www.instagram.com/britanniaxiv/"><Instagram className='text-2xl text-gray-800'/></Link>
                </div>
            </div>

            <div className="w-1/6 flex flex-col text-right">
             
            </div>

            <div className="w-1/6 flex flex-col text-right">
                <h3 className='text-lg'>Links</h3>
                <div className='flex flex-col gap-4 text-gray-800 pt-4'>
                    <Link to="/">Home</Link>
                    <Link to="/groups">Groups</Link>
                    <Link to="/contact">Contact</Link>
                </div>
            </div>


        </div>
        <div className='text-center text-white pb-2'>© Britannia XIV | Lovingly built by <a href="#">Calum Marshall</a></div>
    </footer>
  )
}

export default Footer