import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';




  const EventsMap = ({ events }) => {
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        const fetchCoordinates = async () => {
          const newMarkers = await Promise.all(
            events.sort((a,b) => a.start_date - b.start_date).filter((event) => event.end_date > new Date()).map(async (event) => {
              if (!event.coordinates) {
                event.coordinates = {y: 55.86, x: -4.25};
              }
              return {
                position: [event.coordinates.y, event.coordinates.x],
                event
              };
            })
          );
          setMarkers(newMarkers);
        };
    
        fetchCoordinates();
        setMarkers(offsetMarkers(markers, 1));
        console.log(markers)
      }, [events]);

      function randoLess(n){
        const offsetX = Math.random() * (2 * n) - n;
        const offsetY = Math.random() * (2 * n) - n;
    
        return { offsetX, offsetY };
      }

      function offsetMarkers(markers, offsetAmount) {
        const offsetMarkers = [];
    
        // Iterate through the list of markers
        markers.forEach((marker, i) => {
            // Check if there are other markers with the same coordinates
            const sameCoordsMarkers = markers.filter(m => m !== marker && m.position[0] === marker.position[0] && m.position[1] === marker.position[1]);
    
            if (sameCoordsMarkers.length > 0) {
                // Calculate the offset for latitude and longitude
                const {offsetX, offsetY} = randoLess(i)
           
                const latOffset = offsetAmount * (offsetX );
                const lonOffset = offsetAmount * (offsetY );
    
                // Apply offset to latitude and longitude
                const offsetMarker = {
                    position : [marker.position[0] + latOffset, marker.position[1] + lonOffset],
                    event: marker.event
                };
                console.log("Marker", offsetMarker)
                offsetMarkers.push(offsetMarker);
            } else {
                // If there are no other markers with the same coordinates, add the original marker
                offsetMarkers.push(marker);
            }
        });
    
        return offsetMarkers;
    }
    return (

        <MapContainer center={[55,-2.25]} zoom={6} style={{ width: "100%", height: "50vh"}}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {offsetMarkers(markers,0.005).map(marker => (
          <Marker key={marker.username} position={marker.position}>
            <Popup>
              
                     

              <div  className=" mx-auto  flex flex-col text-wrap gap-4  rounded-lg   text-left  py-8">  
                <b className=''>{marker.event.start_date.toLocaleDateString()} - {marker.event.end_date.toLocaleDateString()}</b>
                <h2 className="mt-1 text-lg leading-tight font-medium text-black ">{marker.event.name}</h2>
                <p className='italic opacity-80'>{marker.event.description}</p>
                <a target="_blank" className='text-xl text-sky-400 text-center'href={"https://www.google.com/maps/dir//" + marker.event.location} >Get Directions</a>
           

              </div>
        

        </Popup>
          </Marker>
        ))}
        </MapContainer>

    );
  };
export default EventsMap;