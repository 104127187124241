import React, {useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Facebook, Instagram } from 'react-bootstrap-icons'
import { isAuthenticated } from '../../helpers/auth';


import Logo from '../../img/Logo.png'

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleLogout = () => {
    // Clear the token from local storage or cookies
    localStorage.removeItem('token');
    // Redirect the user to the login page or any desired route
    navigate('/login');
  };

  return (
    <>
      { location.pathname === "/" ? 
      <header className="flex flex-wrap ">
      <div className="hero flex flex-col items-center w-full ">
        <nav className="bg-transparent border-gray-200 px-2 sm:px-4 py-6 rounded text-gray-300 w-100 w-full">
        <div className={isExpanded ? "container flex flex-wrap items-start justify-between mx-auto" : "container flex flex-wrap items-center justify-between mx-auto"}>
            <Link to="/" className="flex items-center">
                <img src={Logo} className="h-6 mr-3 sm:h-9" alt="Britannia Logo" />
                <h1 className='text-2xl'>Britannia XIV</h1>    
            </Link>
              <button  type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-brand-color " onClick = {() => setIsExpanded(!isExpanded)}>
                <span class="sr-only">Open menu</span>
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              </button>
              <div className='gap-8 hidden md:flex'>
                <Link to="/">Home</Link>
                <Link to="/groups">Groups</Link>
                <Link to="/events">Events</Link>
                <Link to="/contact">Contact</Link>
               
                <Link to="https://www.facebook.com/BritanniaXIV/"><Facebook className=' '/></Link>
                <Link to="https://www.instagram.com/britanniaxiv/"><Instagram className=''/></Link>
              </div>
      </div>
      <div className={isExpanded ? 'flex gap-8 flex-col items-end m-2 md:hidden' : 'hidden'}>
                <Link to="/">Home</Link>
                <Link to="/groups">Groups</Link>
                <Link to="/events">Events</Link>
                <Link to="/contact">Contact</Link>
             
                <Link to="https://www.facebook.com/BritanniaXIV/"><Facebook className=' '/></Link>
                <Link to="https://www.instagram.com/britanniaxiv/"><Instagram className=''/></Link>
              </div>
      </nav>
        <div className='pt-40 pb-60 text-center'>
            <h1 className="text-2xl font-bold leading-snug tracking-tight  text-gray-200 lg:text-2xl lg:leading-tight xl:text-5xl xl:leading-tight ">
              Britannia XIV
            </h1>
            <h2 className="text-xl font-bold leading-snug tracking-tight text-gray-300 lg:text-xl lg:leading-tight xl:text-2xl xl:leading-tight ">
              Non Pertinet Hoc Ad Dignitum
            </h2>
            <p className="py-5 text-xl leading-normal text-gray-400 lg:text-xl xl:text-2xl dark:text-gray-300">
              A medieval re-enactment group recreating life in 14th Century Scotland.
            </p>
        </div>
      </div>
      </header>
      : 
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-6 rounded ">
        <div className={isExpanded ? "container flex flex-wrap items-start justify-between mx-auto" : "container flex flex-wrap items-center justify-between mx-auto"}>
          <Link to="/" className="flex items-center">
              <img src={Logo} className="h-6 mr-3 sm:h-9" alt="Britannia Logo" />
              <h1 className='text-2xl'>Britannia XIV</h1>    
          </Link>
          <button  type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-brand-color " onClick = {() => setIsExpanded(!isExpanded)}>
                <span class="sr-only">Open menu</span>
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              </button>
          <div className='gap-8 hidden md:flex'>
            <Link to="/">Home</Link>
            <Link to="/groups">Groups</Link>
            <Link to="/events">Events</Link>
            <Link to="/contact">Contact</Link>

            <Link to="https://www.facebook.com/BritanniaXIV/"><Facebook className='text-gray-600'/></Link>
            <Link to="https://www.instagram.com/britanniaxiv/"><Instagram className='text-gray-600'/></Link>
          </div>

      </div>
      <div className={isExpanded ? 'flex gap-8 flex-col items-end m-2 md:hidden' : 'hidden'}>
                <Link to="/">Home</Link>
                <Link to="/groups">Groups</Link>
                <Link to="/events">Events</Link>

                <Link to="/contact">Contact</Link>
              
                <Link to="https://www.facebook.com/BritanniaXIV/"><Facebook className=' '/></Link>
                <Link to="https://www.instagram.com/britanniaxiv/"><Instagram className=''/></Link>
      </div>
  
      </nav>

      }
    </>

 
    

  )
}

export default Navbar