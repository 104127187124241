import React from 'react'
import { Link } from 'react-router-dom'
import falkirk from '../../img/falkirk.jpg'
import combat from  '../../img/combat.jpg'
import crafts from '../../img/crafts.jpg'

const Home = () => {
  return (
    <>     
      <section className='flex m-auto text-left py-15vh gap-12 md:flex-col lg:flex-col lg:w-2/3'>
        <div className='lg:w-1/2 text-center'>
          <p className="text-lg" >Britannia XIV is a medieval re-enactment group focusing on the <b>Scottish Wars of Independence</b>, spanning from <b>1296 to 1348</b> .</p>
          <br />
          <p className="text-lg" >Through research and exploration, we delve into the intricacies of this fascinating time period, allowing us to recreate medieval life, crafts, and epic battles.</p>
          <br />
          <br />
          <p className="text-xl"> <Link to="/groups" className='text-sky-500 hover:opacity-60'>Find a group near you.</Link> </p>
        
        </div>
{/*      <div className='lg:w-1/2'>
        <img src={falkirk} alt="Britannia XIV at their display at Falkirk 2022" />
  </div> */}
      </section>
      <section className='flex m-auto py-15vh md:px-20vw text-left gap-12 sm:px-10 md:flex-col lg:flex-row  alternative'>
          <div className='lg:w-1/2'>
            <img src={crafts} alt="Britannia XIV at their display at Falkirk 2022" />
          </div>
          <div className='lg:w-1/2 md:text-left text-center' >
            <p className="text-lg" >At Britannia XIV, we are passionate about reviving traditional skills and techniques. </p>
            <br />
            <p className="text-lg">Our dedicated members engage in a variety of hands-on activities, including spinning, weaving, sewing, cooking, woodworking, maile-making, and blacksmithing. </p>
            <br />
          </div>
      </section>
      <section className='flex m-auto py-15vh md:px-20vw text-left gap-12 sm:px-10 md:flex-col lg:flex-row '>
        <div className='lg:w-1/2 md:text-left text-center'>
          <p  className="text-lg">Britannia XIV also practice a pulled blow form of combat. This allows our members to engage in combat while prioritizing safety without compromising the authenticity of historical warfare.</p>
        </div>
        <div className='lg:w-1/2'>
        <img src={combat} alt="Britannia XIV at their display at Falkirk 2022" />
        </div>
      </section>

    </>
  )
}

export default Home