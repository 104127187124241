import React from 'react'

const About = () => {
  return (
    <>
      <header className=" flex flex-wrap ">
        <div className="hero2 flex flex-col items-center w-full p-40">
            <h1 className="text-xl font-bold leading-snug tracking-tight text-gray-300 lg:text-xl lg:leading-tight xl:text-3xl xl:leading-tight ">
              What do we do?
            </h1>
        </div>
      </header>
      <section className='w-2/3 m-auto'>
        <h1 className='text-2xl mb-16'>About Us</h1>
        <p>Britannia XIV is a medieval re-enactment group recreating the life, crafts and wars of the years 1296 to 1348.</p>
        <p>To achieve this they endeavour to research and explore the period to a high degree of detail using information gleaned to further our various projects.</p>
        <p>Most of these projects rely on historical crafts such as spinning, weaving, sewing, cooking, wood carving, woodturning, maille weaving, armouring and blacksmithing. Many of which are practised and where possible taught by there members.</p>
        <p>Britannia XIV also practice a pulled blow form of armed historical martial arts. This form of martial art is easy to pick up and gives people who practice it a much better understanding of the weapons of the time and their use while still maintaining a form that most can engage in.</p>
        <p>Both the crafts and the combat culminate in there detailed events, shows, living history displays and re-enactments held for the entertainment of the public.</p>



      </section>
    </>
  )
}

export default About