import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {FaCheck} from 'react-icons/fa'

import Membership2023 from '../../components/Forms/Membership2023';
import { getUserData, isAuthenticated } from '../../helpers/auth'
import { isAdmin} from '../../helpers/auth'

import Card from '../../components/User/Card';
import MemberCard from '../../components/User/MemberCard';
import Modal from '../../components/Modal/Modal';
import AdminDashboard from './AdminDashboard';
import MemberDashboard from './MemberDashboard';

function Dashboard() {

  return (


    <>
      {isAuthenticated() ?

      <>

      {isAdmin() 
        ? /*Admins' Dashboard*/
        <>
          <AdminDashboard/>
        </>
        : /*Members' Dashboard*/
        <>
          <MemberDashboard/>
        </>
      }
      </>
      : <section>
      <p>Please log in to access members' dashboard</p>
      </section>}

    </>


  );
}

export default Dashboard;
