import React from 'react'

const Contact = () => {
  return (
    <>
      <header className=" flex flex-wrap ">
      <div className="hero2 flex flex-col items-center w-full py-40">
            <h1 className="text-2xl font-bold leading-snug tracking-tight text-gray-300 lg:text-3xl lg:leading-tight xl:text-3xl xl:leading-tight ">
              Get in touch!
            </h1>
        </div>
      </header>
      <section>
        <h2 className='text-xl'>Use the contact form to get in contact with us. </h2>
        <br />
        
        <form className="text-left  md:w-2/3 lg:w-1/3 " action="https://api.web3forms.com/submit" method="POST">
        <input type="hidden" name="access_key" value="37f74897-8faf-4927-a079-77ac4ad1d940"/>
        <input type="hidden" name="subject" value="New Message from BXIV Website" />
        <input type="hidden" name="redirect" value="https://britanniaxiv.com"/>
        <div className="mb-4">
        <label className="block text-md mb-2" for="name">
            Name
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="text" placeholder="John Smith"/>
        </div>
        <div className="mb-4">
        <label className="block text-md mb-2" for="email">
            Email
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="email" required placeholder="johnsmith@gmail.com" />
        </div>
        <div className="mb-4">
        <label className="block text-md mb-2" for="message">
            Message
        </label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline mb-4" id="message" name="message" type="message" required placeholder="Hello BXIV, I am getting in touch about..."/>
        </div>

        <div className="flex  justify-between">
        <button className=' bg-sky-400 text-gray-200 border-2 border-brand-color hover:bg-brand-color w-full  mb-4 py-4 px-8 rounded-xl' type="submit" >
            Submit
        </button>
        </div>
    </form>

      </section>
    </>
  )
}

export default Contact